<template>
    <v-card :height="fullscreen ? '100vh' : height" :width="fullscreen ? '100vw' : width">
        <v-toolbar v-if="label != null">
            <v-toolbar-title>{{ label }}</v-toolbar-title>
            <slot name="actions" />
        </v-toolbar>
        <VueSignaturePad
            :height="fullscreen ? '100vh' : height"
            id="signature"
            disabled
            ref="signaturePad"
            :width="fullscreen ? '100vw' : width"
            :options="options" />
        <v-overlay :value="isLoading" absolute class="text-center">
            <v-progress-circular indeterminate size="32" />
            <p>Loading Signature</p>
        </v-overlay>
    </v-card>
</template>

<script>
export default {
    name: 'BT-Signature',
    data: function() {
        return {
            isLoading: false,
            // options: {
            //     backgroundColor: this.backgroundColor,
            //     penColor: this.lineColor
            // },
            data: null
        }
    },
    props: {
        backgroundColor: {
            type: String,
            default: 'rgba(0,0,0,0)'
        },
        color: {
            type: String,
            default: null
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: '400px'
        },
        icon: {
            type: String,
            default: 'mdi-draw'
        },
        label: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: '100%' //'auto'
        },
        value: null,
    },
    mounted() {
        this.ensureSignatureLoaded();
    },
    computed: {
        actualHeight() {
            if (this.$refs.signaturePad != null) {
                return this.$refs.signaturePad.signaturePad.canvas.height / window.devicePixelRatio;
            }

            return null;
        },
        actualWidth() {
            if (this.$refs.signaturePad != null) {
                return this.$refs.signaturePad.signaturePad.canvas.width / window.devicePixelRatio;
            }

            return null;
        },
        lineColor() {
            return this.color || (this.$vuetify.theme.dark ? 'white' : 'black');
        },
        options() {
            return {
                backgroundColor: this.backgroundColor,
                penColor: this.color || (this.$vuetify.theme.dark ? 'white' : 'black')
            };
        }
    },
    watch: {
        value: function() {
            this.data = null;
            this.ensureSignatureLoaded();
        }
    },
    methods: {
        ensureSignatureLoaded() {
            this.isLoading = true;
            const interval = setInterval(() => {
                if (this.$refs.signaturePad) {
                    this.loadSignature(this.value);
                    clearInterval(interval);
                }
            }, 1500);
        },
        loadSignature(vArr) {
            var pArr = JSON.parse(vArr);
            if (pArr != null && pArr.length > 0) {
                var pad = this.$refs.signaturePad;
                pad.resizeCanvas();

                this.$nextTick(() => {
                    var rArr = this.reduceToSize(pArr);
                    // var rArr = pArr;
                    // console.log('parr');
                    console.log(rArr);
                    pad.openSignaturePad();
                    pad.fromData([{ color: this.color, points: rArr, width: 50, height: 50 }]);
                    pad.lockSignaturePad();
                    // pad.resizeCanvas();
                })
            }

            this.isLoading = false;
        },
        reduceToSize(arr) {
            console.log(`reducing to ${this.actualHeight} & ${this.actualWidth}`)
            if (this.actualHeight != null && this.actualWidth != null) {
                var maxHeight = Math.max(...arr.map(x => x.y));
                var maxWidth = Math.max(...arr.map(x => x.x));

                var heightRatio = 1;
                var widthRatio = 1;
                if (maxHeight > this.actualHeight) {
                    heightRatio = this.actualHeight / maxHeight;
                }
                
                if (maxWidth > this.actualWidth) {
                    widthRatio = this.actualWidth / maxWidth;
                }

                var ratio = Math.min(heightRatio, widthRatio);

                if (ratio < 1) {
                    return arr.map(z => { return { x: z.x * ratio, y: z.y * ratio } });
                }
            }

            return arr;
        }
    }   
}
</script>